import { FiUserCheck } from 'react-icons/fi'

const TentangKami = () => {
  return (
    <div className="m-12 flex justify-center items-center flex-col">
      <div className="flex items-center mb-8 gap-2">
        <FiUserCheck size={30} className={"text-blue-950"}/>
        <h1 className="text-blue-900 font-bold text-2xl">Tentang Kami</h1>
      </div>
      <div className="bg-white w-3/4 shadow-2xl p-8 flex flex-col gap-4 rounded-xl">
        <p>
          Cluster Sevilla BSD terletak di Kencana Loka, Sektor XII-2, Serpong,
          Kota Tangerang Selatan yang merupakan perumahan yang dikembangkan oleh
          BSD, memiliki sekitar 705 KK terdiri atas 4 (empat) RT yang terletak
          dalam wilayah RW.014, Kelurahan Ciater, dan 3 (tiga) RT yang terletak
          di RW.013 Kelurahan Rawa Mekar Jaya, Kecamatan Serpong.
        </p>
        <p>
          Sehubungan dengan Serah Terima Pengelolan Lingkungan dari PT BSD
          kepada warga maka telah dibentuk Perkumpulan Cluster Sevilla BSD yang
          berfungsi Sebagai Pengelola IPL di Cluster Sevilla.
        </p>
        <p>
          Perkumpulan Warga Cluster Sevilla BSD didirikan berdasarkan Akta No.4
          Tanggal 6 Januari 2017 oleh Notaris Dr. Udin Nasrudin SH, M.Hum, yang
          kemudian disahkan melalui Keputusan Menteri Hukum dan Hak Asasi
          Manusia Republik Indonesia Nomor AHU-0001861.AH.01.07.Tahun 2017
          tanggal 02 Februari 2017.
        </p>
        <p>
          Perkumpulan Cluster Sevilla BSD ini memiliki kepengurusan periode
          Tahun 2017 – 2019 terdiri atas :
        </p>
        <ul className="ml-8">
          <li className=" list-disc">Ketua : Boedi Santoso</li>
          <li className=" list-disc">Sekretaris : Erisnandar</li>
          <li className=" list-disc">Bendahara Umum : IGW Anantayoga</li>
          <li className=" list-disc">Bendahara : Bima Putra</li>
          <li className=" list-disc">Ketua Pengawas : Kunto Nugroho</li>
          <li className=" list-disc">Anggota Pengawas : Madi</li>
          <li className=" list-disc">Anggota Pengawas : Imral Soetarjo</li>
          <li className=" list-disc">Anggota Pengawas : Donanto Indro</li>
          <li className=" list-disc">Anggota Pengawas : Said Sabeq</li>
        </ul>
      </div>
    </div>
  )
}
export default TentangKami
