import { FiSettings } from "react-icons/fi"

const KebijakanPrivasi = () => {
  return (
    <div className="m-12 flex justify-center items-center flex-col">
      <div className="flex items-center mb-8 gap-2">
        <FiSettings size={30} className={'text-blue-950'} />

        <h1 className="text-blue-900 font-bold text-2xl">Syarat & Ketentuan</h1>
      </div>
      <div className="bg-white w-3/4 shadow-2xl p-8 flex flex-col gap-4 rounded-xl">
        <div>
          <h2 className="font-bold text-md text-blue-900">Produk dan Jasa</h2>
          <ul>
            <li>
              a. Pembayaran akan berstatus disetujui ketika Anda menerima
              konfirmasi dari www.clustersevilla.com Konfirmasi tersebut akan
              dikirim melalui email dan atau pesan singkat melalui ponsel kepada
              Anda.
            </li>
            <li>
              b. Pembayaran dapat dilakukan melalui salah satu cara sebagai
              berikut: transfer rekening bank yang telah ditunjuk oleh pihak
              www.clustersevilla.com , kartu kredit, dan Doku Wallet.
            </li>
            <li>
              c. Semua informasi maupun pertanyaan atau komplain dapat langsung
              Anda tanyakan kepada kami atau melalui email ke:
              pwcs.helpdesk@gmail.com.
            </li>
          </ul>
        </div>

        <div>
          <h2 className="font-bold text-md text-blue-900">
            Sistem Pembayaran dari Visa/Master
          </h2>
          <p>
            Untuk memberikan kemudahan dan kepastian bagi pelanggan dalam
            melakukan transaksi pada www.clustersevilla.com, saat ini kami telah
            mengimplementasikan suatu sistem pembayaran online ketika pelanggan
            melakukan pembelian produk secara online dengan menggunakan pilihan
            sistem pembayaran dari Visa/Master dan semua transaksi akan diproses
            dalam mata uang Rupiah Indonesia.
          </p>
          <p>
            Proses pembayaran order Anda kami pastikan aman dengan protokol
            Secure Sockets Layer (SSL) dimana SSL menyediakan keamanan penuh
            setiap pelanggan dan kebebasan untuk belanja online tanpa rasa
            khawatir mengenai kemungkinan pencurian informasi kartu kredit.
          </p>
        </div>

        <div>
          <h2 className="font-bold text-md text-blue-900">
            Nilai pembayaran dan Konfirmasi Pembayaran
          </h2>
          <ul>
            <li>
              a. Nilai pembayaran yang harus dibayarkan adalah sebagaimana yang
              tertera di situs dan atau invoice pembayaran (halaman pembayaran)
            </li>
            <li>
              b. Apabila terdapat perubahan nilai pembayaran , maka nilai
              pembayaran yang berlaku adalah nilai yang sesuai dengan informasi
              total yang ditampilkan dalam halaman pembayaran.
            </li>
          </ul>
        </div>

        <div>
          <h2 className="font-bold text-md text-blue-900">
            Privasi dan Keamanan
          </h2>
          <ul>
            <li>
              a. Kami menjamin keamanan data pribadi Anda yang menggunakan
              layanan www.clustersevilla.com
            </li>
            <li>
              b. Semua informasi pribadi Anda kami lindungi sepenuhnya.
              Kerahasiaan informasi yang Anda masukkan dalam
              www.clustersevilla.com akan kami jaga baik-baik untuk kepentingan
              Anda
            </li>
            <li>
              c. www.clustersevilla.com dapat mengubah dan memperbarui syarat
              dan ketentuan situs ini sewaktu-waktu. Kami akan memberitahu
              perubahan-perubahan tersebut di situs dan e-mail jika dirasa
              perlu. Perubahan dari syarat dan ketentuan akan berlaku segera
              setelah dimasukkan ke dalam situs.
            </li>
          </ul>
        </div>

        <div>
          <h2 className="font-bold text-md text-blue-900">
            Hak Milik Intelektual
          </h2>
          <p>
            Seluruh desain, foto dan gambar yang tertera di
            www.clustersevilla.com tidak dapat disebarluaskan oleh pihak manapun
            dan untuk kepentingan apapun, kecuali ada persetujuan tertulis dari
            pihak www.clustersevilla.com
          </p>
        </div>

        <div>
          <h2 className="font-bold text-md text-blue-900">
            Keluhan atau Pertanyaan
          </h2>
          <p>
            Bila Anda memiliki pertanyaan atau keluhan, Anda dapat menghubungi
            pwcs.helpdesk@gmail.com
          </p>
        </div>

        <div>
          <h2 className="font-bold text-md text-blue-900">
            Penyelesaian dan Hukum
          </h2>
          <ul>
            <li>
              a. Syarat dan ketentuan yang tertera di situs www.clustersevilla.
              com dilindungi oleh Undang-undang dan Hukum yang berlaku di
              Indonesia.
            </li>
            <li>
              b. Segala perselisihan yang timbul dari pelaksanaan situs serta
              layanan yang disediakan www.clustersevilla.com dengan Anda akan
              diselesaikan secara musyawarah dan kekeluargaan, kecuali jika ada
              hal-hal yang memang meresahkan dan menyangkut orang banyak akan
              kami proses secara hukum.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default KebijakanPrivasi
