/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FiX } from 'react-icons/fi'
import parse from 'html-react-parser'
import moment from 'moment'

const DetailBerita = () => {
  const [detailNews, setDetailNews] = useState({})
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const data = async () => {
      setLoading(true)
      try {
        const res = await axios.get(`https://digitalcity.id/api/news/${id}/`)
        setDetailNews(res.data)
        setContent(parse(res.data.content))
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    data()
  }, [])

  const handleImageClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  if (loading) {
    return 'Loading'
  }

  return (
    <section className="lg:px-24 lg:py-16 px-6 py-6">
      {/* <div className="flex items-center justify-center md:my-6 my-12 md:gap-3 gap-1">
        <FiGlobe className="text-bluesaifillah-normal text-[30px] md:text-[50px]" />
        <h1 className="text-[30px] md:text-[50px] font-bold text-bluesaifillah-normal">
          Detail Berita
        </h1>
      </div> */}
      <div className="flex flex-col items-center gap-8">
        <img
          src={detailNews.image}
          alt="Image News"
          className="w-[600px] rounded-[20px] shadow-2xl cursor-pointer"
          onClick={handleImageClick}
        />
        {isModalOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-[99]">
            <div className="bg-white p-4 rounded-lg relative">
              <img
                src={detailNews.image}
                alt="Image News"
                className="w-[900px] rounded-lg shadow-2xl"
              />
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 transition duration-300"
                onClick={handleCloseModal}
              >
                <FiX size={30} />
              </button>
            </div>
          </div>
        )}
        <div className="lg:w-[600px] w-full">
          <p>
            {moment(detailNews?.created_at, 'YYYY-MM-DD | HH:mm:ss').format(
              'ddd, DD MMM YYYY'
            )}
          </p>
          <h1 className="font-bold text-3xl text-bluesaifillah-normal mb-4">
            {detailNews.title}
          </h1>
          <div className="lg:text-[20px] text-[15px]">{content}</div>
        </div>
        <button
          className="p-2 rounded-full w-24 text-white bg-bluesaifillah-normal"
          onClick={() => navigate('/')}
        >
          Back
        </button>
      </div>
    </section>
  )
}
export default DetailBerita
