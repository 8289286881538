import { BrowserRouter } from "react-router-dom";
import Routing from "./Routes/Routing";


function App() {
  return (
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  )
}

export default App;
