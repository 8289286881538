import { FiPhoneCall } from "react-icons/fi"

const KontakKami = () => {
  return (
    <div className="m-12 flex justify-center items-center flex-col">
      <div className="flex items-center mb-8 gap-2">
        <FiPhoneCall size={30} className={'text-blue-900'} />
        <h1 className="text-blue-900 font-bold text-2xl">Kontak PWCS</h1>
      </div>
      <div className="bg-white w-3/4 shadow-2xl p-8 flex flex-col gap-4 rounded-xl">
        <p>Kantor PWCS (Perkumpulan Warga Cluster Sevilla BSD)</p>
        <p>Cluster Sevilla No. AA-13, Samping Lapangan Tenis.</p>
        <div>
          <p>Kantor Buka :</p>
          <p>Senin - Jumat (08.00 - 17.00)</p>
          <p>Sabtu (08.00 - 12.00)</p>
        </div>
        <div>
          <p>Kantor Tutup :</p>
          <p>Minggu/Hari Libur Nasional</p>
        </div>
        <div>
          <p>Kontak yang bisa dihubungi :</p>
          <p>Tlp : 0812 131 9988</p>
          <p>Email : pwcs.helpdesk@gmail.com</p>
        </div>
      </div>
    </div>
  )
}
export default KontakKami
