import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <section className="bg-blue-900 py-24">
      <div className="flex justify-center gap-8">
        <Link to={'/tentang-kami'}>
          <h1 className="text-white text-center hover:scale-110 transition-all cursor-pointer">
            Tentang Kami
          </h1>
        </Link>
        <Link to={'/kebijakan-privasi'}>
          <h1 className="text-white text-center hover:scale-110 transition-all cursor-pointer">
            Kebijakan Dan Privasi
          </h1>
        </Link>
        <Link to={'/kontak-kami'}>
          <h1 className="text-white text-center hover:scale-110 transition-all cursor-pointer">
            Kontak Kami
          </h1>
        </Link>
      </div>
    </section>
  )
}
export default Footer
