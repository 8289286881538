import { FiAlignJustify } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebar } from '../../Features/sidebar/sidebarSlice'
import { useNavigate } from 'react-router-dom'
import LogoSevilla from "../../Assets/logosevilla.png"

const Sidebar = () => {
  const { open } = useSelector((state) => state.sidebar)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const sidebarActiveStyles = `fixed top-0 font-poppins left-0 z-[999] w-full h-auto bg-blue-900 shadow-2xl px-12 overflow-auto transition-all duration-200 ease-in-out ${
    open ? '' : '-translate-y-full'
  }`

  return (
    <aside className={sidebarActiveStyles}>
      <div className="flex justify-between items-center px-4 py-6">
        <img src={LogoSevilla} alt="logo" className="w-12" />
        <FiAlignJustify
          size={30}
          className="text-white cursor-pointer"
          onClick={() => dispatch(toggleSidebar())}
        />
      </div>
      {/* add your sidebar content here */}
      <div className="flex flex-col items-center">
        <ul className="gap-9 items-center text-white flex flex-col justify-center py-6">
          <li onClick={() => {
            navigate('/')
            dispatch(toggleSidebar())
          }}>Home</li>
        </ul>
        {/* <button className="w-24 bg-[#98E3F5] h-[44px] rounded-lg my-6">
          Login
        </button> */}
      </div>
    </aside>
  )
}

export default Sidebar
