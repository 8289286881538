import { FiAlignJustify } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { toggleSidebar } from '../../Features/sidebar/sidebarSlice'
import { useNavigate } from 'react-router-dom'
import LogoSevilla from '../../Assets/logosevilla.png'

const Navbar = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()


  const navbarClasses = `w-full h-24 fixed z-[99] top-0 flex justify-between items-center lg:px-48 px-16 transition-all duration-700 bg-white backdrop-blur-xl shadow-xl sticky`

  return (
    <div className={navbarClasses}>
      <img src={LogoSevilla} alt="logo" className='w-12'/>
      <div className="flex gap-6 font-poppins">
        <FiAlignJustify
          size={30}
          className={`lg:hidden text-blue-600`}
          onClick={() => dispatch(toggleSidebar())}
        />
        <ul
          className={`lg:flex hidden gap-9 items-center text-blue-600`}
        >
          <li onClick={() => navigate('/')} className="cursor-pointer">Home</li>
          {/* <li
            ref={pembantukuRef}
            className={`relative flex items-center cursor-pointer ${
              isPembantukuOpen ? 'active transition-all' : ''
            }`}
            onClick={() => setIsPembantukuOpen(!isPembantukuOpen)}
          >
            Profesi <FiChevronDown />
            {isPembantukuOpen && (
              <ul className="absolute top-8 left-0 mt-2 p-4 bg-white shadow-lg rounded-lg w-[180px] text-black flex flex-col gap-2 shadow">
                <li className='hover:text-blue-600' onClick={() => navigate('/prt')}>PRT</li>
                <li className='hover:text-blue-600' onClick={() => navigate('/cleaningservice')}>Cleaning Service</li>
                <li className='hover:text-blue-600' onClick={() => navigate('/babysiter')}>Baby Siter</li>
              </ul>
            )}
          </li> */}
          {/* <li
            ref={profileRef}
            className={`relative flex items-center cursor-pointer ${
              isProfileOpen ? 'active' : ''
            }`}
            onClick={() => setIsProfileOpen(!isProfileOpen)}
          >
            Profile <FiChevronDown />
            {isProfileOpen && (
              <ul className="absolute top-8 left-0 mt-2 p-4 bg-white shadow-lg rounded-lg w-[180px] text-black flex flex-col gap-2 shadow">
                <li className='hover:text-blue-600' onClick={() => navigate('/artikel')}>Artikel</li>
                <li className='hover:text-blue-600' onClick={() => navigate('/kontakkami')}>Kontak Kami</li>
                <li className='hover:text-blue-600' onClick={() => navigate('/tentangkami')} >Tentang Kami</li>
              </ul>
            )}
          </li> */}
        </ul>
        {/* <button onClick={() => navigate('/login')} className="w-24 bg-[#4313E1] text-white h-[44px] rounded-lg hidden lg:flex lg:items-center lg:justify-center">
          Login
        </button> */}
      </div>
    </div>
  )
}

export default Navbar
