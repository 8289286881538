import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Carousel } from 'antd'
import BannerHome2 from '../../Assets/1.png'
import BannerHome3 from '../../Assets/2.png'
import Playstore from '../../Assets/playstore.png'
import Appstore from '../../Assets/appstore.png'
import LogoSevilla from '../../Assets/logosevilla.png'
import Img1 from '../../Assets/fasilitas.png'
import Img2 from '../../Assets/covid.png'
import Img3 from '../../Assets/security.png'
import Img4 from '../../Assets/speed.png'
import axios from 'axios'
import parse from 'html-react-parser'
import moment from 'moment/moment'

const Home = () => {
  const [news, setNews] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const dataNews = async () => {
      try {
        const res = await axios.get(
          'https://digitalcity.id/api/newsbyidcluster/1/'
        )
        // console.log(res.data)
        setNews(res.data)
      } catch (err) {
        console.log(err)
      }
    }
    dataNews()
  }, [])

  return (
    <div>
      {/* Section Banner */}
      <Carousel
        autoplay
        // className="w-screen h- flex justify-center items-center"
        dots={true}
      >
        {/* <div className="h-[28rem]">
          <div className="bg-[#4313E1] h-full text-center bg-no-repeat bg-cover bg-center flex flex-col justify-center text-blue-500 gap-4">

          </div>
        </div> */}
        <div className="h-[37rem]">
          <div className="bg-blue-900 lg:px-44 px-12  h-full bg-no-repeat bg-cover bg-center lg:flex flex lg:flex-row flex-col justify-center items-center gap-12">
            <div className="w-40 h-40 bg-white flex items-center justify-center rounded-xl">
              <img src={LogoSevilla} alt="logo" className="w-24" />
            </div>
            <div className="flex flex-col text-center lg:text-left">
              <h1 className="font-semibold lg:text-[55px] text-white text-[33px]">
                Selamat Datang
              </h1>
              <p className="text-white lg:text-3xl text-lg">
                Perkumpulan Warga Cluster Sevilla BSD (PWCS)
              </p>
            </div>
          </div>
        </div>
        <div className="h-[37rem]">
          <div className="bg-blue-900 lg:px-44 px-12  h-full bg-no-repeat bg-cover bg-center lg:flex flex lg:flex-row flex-col justify-center items-center gap-12">
            <div className="flex items-center justify-center rounded-xl">
              <img src={BannerHome2} alt="logo" className="w-32" />
              <img src={BannerHome3} alt="logo" className="w-32" />
            </div>
            <div className="flex flex-col text-center lg:text-left">
              <h1 className="font-semibold lg:text-[55px] text-white text-[33px]">
                Sevilla Digital Apps
              </h1>
              <p className="text-white lg:text-2xl text-lg">
                Powered By digitalcity.id
              </p>
            </div>
          </div>
        </div>
        <div className="h-[37rem]">
          <div className="bg-blue-900 lg:px-44 px-12  h-full bg-no-repeat bg-cover bg-center lg:flex flex flex-col justify-center items-center gap-12">
            <h1 className="font-semibold lg:text-[55px] text-white text-[33px]">
              Silahkan Unduh Platform Kami Melalui
            </h1>
            <div className="flex items-center justify-center rounded-xl gap-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.dcitymobile.sevilla"
                target={'_blank'}
                rel="noreferrer"
              >
                <img src={Playstore} alt="logo" className="w-32" />
              </a>
              <a
                href="https://apps.apple.com/app/sevilla-digital-apps/id6449644877"
                target={'_blank'}
                rel="noreferrer"
              >
                <img src={Appstore} alt="logo" className="w-32" />
              </a>
            </div>
          </div>
        </div>
        {/* <div className="h-[37rem]">
          <div
            className="lg:px-44 px-12  h-full bg-no-repeat bg-cover bg-center lg:flex flex flex-col justify-center items-center gap-12"
            style={{
              backgroundImage: `url('${Img1}')`,
            }}
          ></div>
        </div>
        <div className="h-[37rem]">
          <div
            className="lg:px-44 px-12  h-full bg-no-repeat bg-cover bg-center lg:flex flex flex-col justify-center items-center gap-12"
            style={{
              backgroundImage: `url('${Img2}')`,
            }}
          ></div>
        </div>
        <div className="h-[37rem]">
          <div
            className="lg:px-44 px-12  h-full bg-no-repeat bg-cover bg-center lg:flex flex flex-col justify-center items-center gap-12"
            style={{
              backgroundImage: `url('${Img3}')`,
            }}
          ></div>
        </div>
        <div className="h-[37rem]">
          <div
            className="lg:px-44 px-12  h-full bg-no-repeat bg-cover bg-center lg:flex flex flex-col justify-center items-center gap-12"
            style={{
              backgroundImage: `url('${Img4}')`,
            }}
          ></div>
        </div> */}
        {/* <div className="w-full h-screen flex justify-center items-center">
          <img src={BannerHome} alt="Banner" />
        </div> */}
      </Carousel>

      <section className="lg:px-44 py-16">
        <div className="pb-6">
          <h1 className="font-semibold lg:text-5xl text-center text-[30px]">
            Berita Terbaru
          </h1>
        </div>
        <div className="my-6 w-full md:gap-5 flex flex-wrap justify-center">
          {news.length !== 0 ? (
            news.map((value, index) => {
              // console.log(value)
              // const getToken = getUserToken()
              return (
                <div
                  key={value.id}
                  className="md:w-[280px] w-[170px] lg:h-[28rem] h-[24rem] rounded overflow-hidden drop-shadow-xl bg-white mb-5 mx-2"
                >
                  <img
                    className="w-full"
                    src={value?.image}
                    alt={value?.image}
                  />
                  <div className="md:px-6 px-2 py-4">
                    <p>
                      {moment(
                        value?.created_at,
                        'YYYY-MM-DD | HH:mm:ss'
                      ).format('ddd, DD MMM YYYY')}
                    </p>
                    <div className="font-bold md:text-xl text-sm mb-2 flex justify-between items-center">
                      <p>{value?.title}</p>
                      {/* {getToken ? (
                        <FiEdit
                          color="rgb(21,110,152)"
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(`/edit-berita/${value.id}`, {
                              state: { news: value },
                            })
                          }
                        />
                      ) : null} */}
                    </div>
                    <div
                      className="text-gray-700 md:text-sm text-xs w-auto"
                      // onClick={() => handleClick(index)}
                    >
                      { parse(value?.content?.slice(0, 160) + '...') }
                    </div>
                    <div
                      className="flex justify-center gap-1 mx-4 absolute inset-x-0 bottom-0 items-center my-2 py-2 bg-green-600 text-white"
                      onClick={() => navigate('/berita/' + value?.id)}
                    >
                      Read More
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <div>Belum Ada Berita</div>
          )}
        </div>
        {/* {showFullText && (
          <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-[99]">
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-2/5 w-3/4 max-h-4/5 overflow-y-scroll bg-white rounded-md p-4">
              <div className="font-bold text-xl mb-2">
                {news[selectedNews].title}
              </div>
              <div className="text-gray-700 text-base">
                {parse(news[selectedNews].content)}
              </div>
              <button
                className="mt-4 w-full h-auto bg-bluesaifillah-normal text-blue-500 p-3 rounded-full"
                onClick={() => setShowFullText(false)}
              >
                Close
              </button>
            </div>
          </div>
        )} */}
      </section>
    </div>
  )
}
export default Home
