import { Route, Routes } from "react-router-dom";
import LayoutNav from "../Layouts/LayoutNav";
import Home from "../Pages/Home";
import DetailBerita from "../Pages/Berita/DetailBerita";
import TentangKami from "../Pages/Section/TentangKami";
import KebijakanPrivasi from "../Pages/Section/KebijakanPrivasi";
import KontakKami from "../Pages/Section/KontakKami";

const Routing = () => {
  return (
    <Routes>
      <Route element={<LayoutNav />}>
        <Route path="/" element={<Home />} />
        <Route path="/berita/:id" element={<DetailBerita />} />
        <Route path="/tentang-kami" element={<TentangKami/>} />
        <Route path="/kebijakan-privasi" element={<KebijakanPrivasi/>}/>
        <Route path="/kontak-kami" element={<KontakKami/>} />
      </Route>
    </Routes>
  )
};
export default Routing;
